<template>
  <v-dialog v-if="project" id="dialog" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn 
        v-on="on" 
        text 
        class="primary--text"
        :disabled="project.secretCount >= project.quota.secrets"
      >
        <v-icon class="mr-1">add_box</v-icon>        
        New Secret
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Create New Secret</v-card-title>
      <v-card-text>
        <form v-on:submit.prevent="createSecret()">
          <v-text-field required label="Name" v-model="secret.name" />
          <v-radio-group row v-model="secretValueType" mandatory>
            <template v-slot:label>
              <div>Secret Type: </div>
            </template>
            <v-radio                            
              :label="`Environment`"
              value="Environment"
            ></v-radio>
            <v-radio                            
              :label="`File`"
              value="File"
            ></v-radio>
            <v-radio                            
              :label="`File (Upload)`"
              value="FileUpload"
            ></v-radio>
          </v-radio-group>
          <!-- Normal env variable secret -->
          <div v-if="secretValueType === 'Environment'">
            <v-text-field required label="Value" v-model="secret.value" />
            <p class="text-caption">
              Create environment variable based secrets that can store database, applications 
              credentials or any other configuration values.
            </p>
          </div>
          <!-- File variable -->
          <div v-else-if="secretValueType === 'FileUpload'">
            <v-file-input
              show-size
              label="Select File..."
              truncate-length="15"
              v-model="secretFile"
              accept="*/*" 
              @change="onFilePicked"
            ></v-file-input>
            <p class="text-caption">
              Upload files that can then be used by applications.
            </p>
          </div>
          <!-- File but text input -->
          <div v-else>
            <codemirror              
              ref="secretCreateCm"                                
              v-model="secretFileText"
              :options="cmOptions"
            ></codemirror>
            <p class="text-caption mt-4">
              Create a file based secret by copying configuration directly into the text field above.
            </p>
          </div>     
          
          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-show="error" class="mt-4">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-on:click.native="dialog = false" class="primary--text">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading || uploading" :loading="loading" v-on:click.native.stop="createSecret()" class="primary">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
  
  .CodeMirror {
    border: 1px solid #eee;
    min-height: 100px;
    height: auto;
  }
</style>

<script>
import { codemirror } from 'vue-codemirror';

// require styles
import 'codemirror/lib/codemirror.css';

// theme css
import 'codemirror/theme/base16-dark.css';

  export default {
    components: {
      codemirror
    },
    data () {
      return {
        dialog: false,

        secretFile: null,
        secretFileContents: '',
        
        secretFileText: '', // Used when creating a text file

        cmOptions: {
          // codemirror options
          tabSize: 4,
          // mode: 'text/x-yaml',
          theme: 'base16-dark',
          lineNumbers: true,
          line: true,
          fixedGutter: false // https://github.com/surmon-china/vue-codemirror/issues/112
        },

        // We can have:
        // - Environment (normal small text field)
        // - FileUpload (file upload)
        // - File (file but text input, could be much larger)
        secretValueType: '',

        secret: {
          name: '',
          value: '',
          type: ''
        },

        uploading: false
      }
    },

    computed: {
      loading() {
        return this.$store.state.secret.loading
      },
      error() {
        return this.$store.state.secret.error
      },
      selectedProjectId() {
        return this.$route.params.project
      },
      project() {
        return this.$store.state.project.project
      },
      selectedNamespaceId() {
        return this.$route.params.namespace
      }
    },

    methods: {
      onFilePicked () {
        this.uploading = true
        let reader = new FileReader()
        // Uploading secret file
        reader.onload = () => {          
          this.secretFileContents = reader.result
          this.uploading = false
        }
        reader.readAsText(this.secretFile)
      },
      createSecret () {

        let encodedData = ''

        console.log(this.secretValueType)
        console.log(this.secretFileText)

        // Determining secret type and encoding the contents
        switch (this.secretValueType) {
          case 'Environment':
            encodedData = btoa(this.secret.value)
            this.secret.type = 'Environment'
            break
          case 'File':
            encodedData = btoa(this.secretFileText)
            this.secret.type = 'File'
            break
          case 'FileUpload':
            if (this.secretFileContents == '') {
              // File not set, nothing to do
              return 
            }
            encodedData = btoa(this.secretFileContents)
            this.secret.type = 'File'
            break
        }

        let payload = {
          projectId: this.selectedProjectId,
          namespaceId: this.selectedNamespaceId,
          name: this.secret.name,
          data: encodedData,
          type: this.secret.type,
        }
        this.$store.dispatch('CreateSecret', payload).then(() => {
          if (this.error != null) {
            // got error, don't reset form, let user
            // fix the mistake :S
            return
          }
          this.$store.dispatch('Notify', `Secret '${payload.name}' created`)
          let q = {
            projectId: this.selectedProjectId,
            namespaceId: this.selectedNamespaceId
          }
          this.$store.dispatch('ListSecrets', q)
          this.resetForm()
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false

        // resetting form
        this.secret.name = ''
        this.secret.value = ''
      }
    }
  }
</script>
